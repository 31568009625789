<template>
	<div class="wrap">
		<el-form :inline="true" :model="searchForm" class="demo-form-inline">
			<el-form-item label="员工">
				<el-select v-model="searchForm.sale" placeholder="请选择">
					<el-option v-for="item in options" :key="item.id" :label="item.nickname" :value="item.id">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="提成类型">
				<el-select v-model="searchForm.type" placeholder="请选择">
					<el-option v-for="item in types" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="日期范围">
				<el-date-picker v-model="daterange" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
				value-format="yyyy-MM-dd">
				</el-date-picker>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="pageChange(1)">查询</el-button>
			</el-form-item>
		</el-form>
		<div class="text-grey">该查询条件下，总提成金额为：<span class="text-green text-lg">￥{{money2}}</span></div>
		<el-table :data="tableData" v-loading="loading">
			<el-table-column type="index" width="50">
			</el-table-column>
			<el-table-column prop="nickname" label="员工姓名">
			</el-table-column>
			<el-table-column prop="rule_type" label="提成类型">
				<!-- 1:统一销售提成 2：充值销售单独核算 3：商品销售单独核算 4：服务销售单独核算 5:服务卡销售单独核算 6:使用商品设置的提成金额 7：套餐单独核算 -->
				<template slot-scope="scope">
					<div v-if="scope.row.rule_type == 1">统一销售提成</div>
					<div v-if="scope.row.rule_type == 2">充值销售单独核算</div>
					<div v-if="scope.row.rule_type == 3">商品销售单独核算</div>
					<div v-if="scope.row.rule_type == 4">服务销售单独核算</div>
					<div v-if="scope.row.rule_type == 5">服务卡</div>
					<div v-if="scope.row.rule_type == 6">产品</div>
					<div v-if="scope.row.rule_type == 7">套餐销售单独核算</div>
					<div v-if="scope.row.rule_type == 8">技师服务提成</div>
				</template>
			</el-table-column>
			<el-table-column prop="order_no" label="来源订单">
			</el-table-column>
			<el-table-column prop="product_name" label="来源商品/服务">
			</el-table-column>
			<el-table-column prop="amount" label="提成金额">
			</el-table-column>
			<el-table-column prop="rule" label="结算规则">
			</el-table-column>
			<el-table-column prop="created_at" label="结算时间">
			</el-table-column>
		</el-table>
		<div class="text-center margin-top">
			<el-pagination @current-change="pageChange" :current-page="searchForm.page" :page-sizes="vuex_page_sizes" :page-size="searchForm.page_size"
			layout="total, sizes, prev, pager, next, jumper" :total="searchForm.total" @size-change="pageSizeChange">
			</el-pagination>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				loading: false, // 表格加载状态
				tableData: [], // 数据
				options: [],
				money: 0, // 金额
				types: [{
						value: '',
						label: '全部提成类型'
					}, {
						value: 1,
						label: '统一销售提成'
					}, {
						value: 2,
						label: '充值销售单独核算'
					}, {
						value: 3,
						label: '商品销售单独核算'
					}, {
						value: 4,
						label: '服务销售单独核算'
					},
					// {
					// 	value: 5,
					// 	label: '服务卡销售单独核算'
					// }, 
					{
						value: 7,
						label: '套餐销售单独核算'
					}, {
						value: 8,
						label: '技师服务提成'
					}
				],
				daterange: [],
				searchForm: {
					sale: '',
					type: '',
					page: 1, // 否 int 页码
					page_size: 10, // 否 int 页脚
					total: 0,
				}
			};
		},
		computed: {
			money2() {
				return this.money.toFixed(2)
			}
		},
		mounted() {
			// 获取员工列表
			this.$api.user.getUsers({
				page: 1,
				page_size: 1000
			}).then().then(res => {
				this.options = res.data.data;
				this.options.unshift({
					id: '',
					nickname: '全部员工'
				})
				console.log(this.options)
			});
			this.search()
		},
		methods: {
			search() {
				let dr = this.daterange || [];
				this.searchForm.start_date = dr[0] ? dr[0] + ' 00:00:00' : undefined;
				this.searchForm.end_date = dr[1] ? dr[1] + ' 23:59:59' : undefined;
				this.loading = true;
				this.$api.store.getSaleRecords(this.searchForm).then(res => {
					this.loading = false;
					this.tableData = res.data.data;
					this.searchForm.total = res.data.count;
					this.money = Number(res.data.money);
				})
			},
			pageSizeChange(size) {
				this.searchForm.page_size = size;
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.search()
			},
		},
	};
</script>

<style scoped lang="scss">

</style>
